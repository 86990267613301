<template>
  <ValidationProvider
    mode="lazy"
    :rules="{
      regex: /([1-9][0-9])(\s)(\d{3})(\s)(\d{4})/,
      required: true,
    }"
    v-slot="{ errors, validated, valid }"
  >
    <b-field
      v-bind="$attrs"
      :type="{
        'is-danger': errors[0],
        'is-success': validated && valid,
      }"
      label-position="on-border"
      :message="errors"
      class="validator-field"
      :class="fieldClass"
      :label="label"
    >
      <span class="phone-input-group">
        <b-input
          v-model="formattedValue"
          type="tel"
          inputmode="numeric"
          v-bind="$attrs"
          v-mask="'## ### ####'"
          class="phone-input"
        ></b-input>
        <p class="control phone-prefix">
          <b-input value="+36" disabled></b-input>
        </p>
      </span>
    </b-field>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    vid: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    fieldClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formattedValue: null,
    };
  },
  watch: {
    formattedValue() {
      let phoneNumber = "36".concat(
        this.formattedValue
          ? this.formattedValue.replace(/\D+/g, "")
          : this.formattedValue
      );
      this.$emit("input", phoneNumber);
    },
    value: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          if (newVal.startsWith("36")) {
            newVal = newVal.substring(2, newVal.length);
          }
          if (newVal.length > 9) {
            newVal = newVal.substring(0, 9);
          }
        }
        this.formattedValue = newVal;
      },
    },
  },
  methods: {
    forceUpdate(value) {
      this.innerValue = value;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/colors.scss";

.phone-input-group {
  display: flex;

  .phone-input {
    order: 2;
    width: 100%;

    input {
      width: 100%;
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
      }
    }
  }

  .phone-prefix {
    order: 1;
    width: 3.5rem;

    .control {
      .input {
        width: 100%;
        padding-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 0;
        border-right: none;
        border-color: $grey-lightest;

        &.is-danger {
          border-color: #f14668;
        }

        &.is-success {
          border-color: #48c78e;
        }
      }
    }

    .icon.is-right {
      display: none;
    }
  }
}
</style>
